import https from "./https"
import store from "@/store"

const redeemCode = {
  getRedeemCodes(params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/redeem-codes`, {params})
  },

  exportRedeemCodes(params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/redeem-codes/export`, {params})
  }
}

export default redeemCode
