export default {
  data() {
    const fromMyConsole = this.$route.path.includes('dashboard/my-console')
    return {
      fromMyConsole,
      listRouteName: fromMyConsole ? 'MyConsoleRedeemCodeBundleList' : 'RedeemCodeBundleList',
      listTitle : fromMyConsole ? '我的優惠券' : '優惠券管理',
    }
  },
}
