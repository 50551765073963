import https from "@/apis/https"

const me = {
  getCustomers(params) {
    return https.get(
      `/admin/me/customers`,
      { params }
    );
  },
  changeStaff(params) {
    return https.post(
      `/admin/me/customers/change-staff`,
      params
    );
  },
  unbindCustomers(params) {
    return https.post(
      `/admin/me/customers/unbind-customers`,
      params
    );
  },
  changeBoundType(params) {
    return https.post(
      `/admin/me/customers/change-bound-type`,
      params
    );
  },
}

export default me
